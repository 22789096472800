<template>
  <div class="app-container invoice-account">
    
    <div class="filter-container" style="min-height: auto;width: 100%">
      <el-form :inline="true" :model="queryForm" ref="queryForm" class="form-inline tz" style="width: 100%">
        <el-row style="width: 100%">
          <!-- 开票企业 发票代码 发票号码 发票类型 开票日期起 开票日期止 发票状态 开票类型 数据写入时间起 数据写入时间止
companyId invoiceCode invoiceNo invoiceType invoiceTimeBegin invoiceTimeEnd invoiceStatus type createTimeStart createTimeEnd -->
          <el-form-item label="开票日期" label-width="15%"  style="width: 30%" class="range-query">
            <el-date-picker
                v-model="startDate"
                type="date"
                placeholder="开始日期"
                :picker-options="startDatePicker"
                style="width: 45%"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd"
            />
              至
            <el-date-picker
                v-model="endDate"
                type="date"
                placeholder="结束日期"
                :picker-options="endDatePicker"
                style="width: 45%"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item label="开票企业" prop="companyId" style="width: 15%" label-width="30%" class="single_query">
            <el-input placeholder="请输入查询条件" v-model="queryForm.companyId" maxlength="50"  class="filter-item" @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item label="发票代码" prop="invoiceCode" style="width: 16%" label-width="30%" class="single_query removeControl">
            <el-input placeholder="请输入查询条件" v-model="queryForm.invoiceCode" oninput="if(value.length>25)value=value.slice(0,25)" class="filter-item" type='number'  @keyup.enter.native="handleQuery"/>
          </el-form-item>
          
          <el-form-item label="发票号码" prop="invoiceNo" style="width: 16%" label-width="35%" class="single_query removeControl">
            <el-input placeholder="请输入查询条件" v-model="queryForm.invoiceNo" oninput="if(value.length>25)value=value.slice(0,25)" class="filter-item" type='number'  @keyup.enter.native="handleQuery"/>
          </el-form-item>
          <el-form-item label="发票类型" prop="invoiceType" style="width: 16%" label-width="40%" class="single_query">
            <el-select v-model="queryForm.invoiceType" placeholder="请选择" clearable  class="filter-item" >
              <el-option v-for="item in invoiceTypeOptions" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row style="width: 100%">
           <el-form-item label="数据写入" label-width="15%"  style="width: 30%" class="range-query">
            <el-date-picker
                v-model="createStart"
                type="date"
                placeholder="开始日期"
                :picker-options="createStartDatePicker"
                style="width: 45%"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd"
            />
              至
            <el-date-picker
                v-model="createEnd"
                type="date"
                placeholder="结束日期"
                :picker-options="createEndDatePicker"
                style="width: 45%"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <!-- <el-form-item label="发票状态" prop="invoiceStatus" style="width: 15%" label-width="30%" class="single_query">
            <multipleSelect
                :selectOptions="selectOptions"
                :mulSelectLoading="mulSelectLoading"
                :mulSelecteds="queryForm.invoiceStatus"
                @update:updateMultipleSelect="updateMultipleSelect" class="filter-item">
            </multipleSelect>
          </el-form-item> -->
          <el-form-item label="发票状态" prop="invoiceStatus" style="width: 15%" label-width="30%" class="single_query">
            <el-select v-model="queryForm.invoiceStatus" placeholder="请选择" clearable  class="filter-item" >
              <el-option v-for="item in invoiceStatusOptions" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
          </el-form-item>
          <el-form-item label="开票类型" prop="issueType" style="width: 16%" label-width="30%" class="single_query">
            <el-select v-model="queryForm.issueType" placeholder="请选择" clearable  class="filter-item" >
              <el-option v-for="item in billingTypeOptions" :key="item.value" :label="item.key" :value="item.value"/>
            </el-select>
          </el-form-item>

          <el-form-item style="width: 14%" class="button_query">
            <el-button v-waves class="filter-item" style="margin-left: 10%;" type="primary" icon="el-icon-search" @click="handleQuery" ref="search" >搜索</el-button>
            <el-button v-waves class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-refresh" @click="handleReset()" >重置</el-button>
          </el-form-item>

        </el-row>
        <!-- <el-row style="width: 100%">
        <el-form-item style="width: 15%" class="button_query">
          <el-button v-waves class="filter-item" style="margin-left: 10%;" type="primary" icon="el-icon-search" @click="handleQuery" ref="search" >搜索</el-button>
          <el-button v-waves class="filter-item" style="margin-left: 10px;" type="primary" icon="el-icon-refresh" @click="handleReset()" >重置</el-button>
        </el-form-item>
        </el-row> -->
      </el-form>
    </div>

    <div class="batch_button" >
      <div class="btnBox">
        <div></div>
        <div>
          <el-button class="btn1 iconfont iconicon-04" @click="batchExport">&nbsp;导出</el-button>
          <el-button class="btn1" @click="createPDF">&nbsp;重新生成PDF</el-button>
          <el-button class="btn1" @click="pushEmail">&nbsp;推送邮箱</el-button>
        </div>
       
      </div>
    </div>
    <div class="table">
      <comps-table :tableData="tableData" :tableHeader="tableHeader" v-loading='isLoading' :hideSinglePage='true' class="comp_table" v-if="hasData"
                   :selectionType='true' @multipleSelection='selection' :total='total' :pageSize='queryForm.rows' @current-events="handleCurrentChange" @size-events="changeSize" :pageSizeList='pageSizeList' :page='queryForm.page' height="84%">
      </comps-table>
      <nodata v-else message="暂无数据" class="no_data" v-loading="isLoading" />
    </div>

 

    
    <export-dialog :visible='exportVis'  @selectType="selectType" :isdeling='isdeling' :confirmDialog='confirmExport' :cancelDialog='exportCancel' ref="export"/>

    <!-- 重新生成PDF -->

    <!-- 预览 -->
    <preview-dialog  ref="pdf"/>
    <!-- <previews-dialog :visible='previewsVis' :cancelDialog='previewsCancel' :itemId="itemId" :imgUrl="imgUrl"/> -->
    <!-- 推送邮箱 -->
    <push-dialog :visible='pushVis' :cancelDialog='pushCancel' :confirmDialog="pushConfirm" :isdeling='isdeling'/>
    <!-- 自动推送邮箱 -->
    <auto-dialog :visible='autoPush' @selectEmailType="selectEmailType" :cancelDialog='autoPushCancel' :confirmDialog="autoPushConfirm" :isdeling='isdeling'/>
  </div>
</template>

<script>
  import compsTable from "@/components/table/table";
  import moment from 'moment'
  import multipleSelect from '@/components/multiSelectDropDownBox';
  // import previewDialog from '@/components/previewDialog'
  import exportDialog from './dialog/export' //导出
  // import previewsDialog from './dialog/previews'//预览
  import previewDialog from './dialog/previews'//预览
  import pushDialog from './dialog/push'//推送
  import autoDialog from './dialog/autoPush'//自动推送
  import axios from 'axios'
  import request from "@/utils/request";
  export default {
    components: {  compsTable ,exportDialog,previewDialog,multipleSelect,pushDialog,autoDialog},
    data() {
      return {
//         开票企业 发票代码 发票号码 发票类型 开票日期起 开票日期止 发票状态 开票类型 数据写入时间起 数据写入时间止
// companyId invoiceCode invoiceNo invoiceType invoiceTimeBegin invoiceTimeEnd invoiceStatus type createTimeStart createTimeEnd
        queryForm: {
          companyId: '',    //开票企业
          invoiceCode: '',    //发票代码
          invoiceNo: '',    //发票号码
          invoiceType: '',    //发票类型
          // invoiceStatus: '',    //发票状态
          invoiceStatus: [],    //发票状态
          issueType: '',    //开票类型
          invoiceTimeBegin: '', //开票日期起
          invoiceTimeEnd: '',  //开票日期止
          createTimeBegin: '', //数据写入日期开始
          createTimeEnd: '',  //数据写入日期截止
          page: 1,
          rows: 20
        },
        exportFrom:{},
        isExport:false,//是否可以导出
        total: 0,
        pageSizeList: [20, 50, 100, 200],
        //----开票日期----
        startDate:moment().month(moment().month()).startOf('month').format('YYYY-MM-DD'),//当前月开始时间,
        endDate:moment().month(moment().month()).endOf('month').format('YYYY-MM-DD'),//当前月结束时间,
        startDatePicker: this.beginDate(),
        endDatePicker: this.processDate(),
        //----数据写入----
        createStart:undefined,//数据写入日期开始,
        createEnd:undefined,//数据写入日期开始,
        createStartDatePicker: this.createStartDate(),
        createEndDatePicker: this.createEndDate(),
        
        invoiceTypeOptions:[{'key': '电子普票','value': '1'},{'key': '电子专票','value': '2'}],
        billingTypeOptions:[{'key': '正数发票','value': '0'},{'key': '负数发票','value': '1'}],
        invoiceStatusOptions:[{'key': '正常','value': '1'},{'key': '已作废','value': '2'},{'key': '已红冲','value': '3'}],
        isLoading: false,
        hasData: true,
        // 开票类型 开票企业 开票企业税号 购买方名称 购买方邮箱 发票请求流水号 开票日期|发票类型|发票代码|发票号码|开票金额|发票状态|PDF生成状态|邮箱推送状态|数据写入时间
        //issueType companyName taxId buyerName buyerEmail issueSerialNumber invoiceTime invoiceType invoiceCode invoiceNo invoiceAmount invoiceStatus pdfStatus pushStatus createTime
        tableHeader: [
          { prop: "issueType", label: "开票类型", center: 'center',width:'120',
            formatter: (row) => {
              const typeMap = {
                '0': '正数发票',
                '1': '负数发票',
              }
              return typeMap[row.issueType];
            },
          },
          { prop: "companyName", label: "开票企业", center: 'center',width:'120'},
          { prop: "taxId", label: "开票企业税号", center: 'center',width:'120'},
          { prop: "buyerName", label: "购买方名称", center: 'center',width:'120',},
          { prop: "buyerEmail", label: "购买方邮箱", center: 'center',width:'120'},
          { prop: "issueSerialNumber", label: "发票请求流水号", center: 'center',width:'130'},
          { prop: "invoiceTime", label: "开票日期", center: 'center',width:'120',
            formatter: (row) => {
              return row.invoiceTime.slice(0,10);
            },
          },
          { prop: "invoiceType", label: "发票类型", center: 'center',width:'120',
            formatter: (row) => {
              const typeMap = {
                '1': '电子普票',
                '2': '电子专票',
              }
              return typeMap[row.invoiceType];
            },
          },
          { prop: "invoiceCode", label: "发票代码", center: 'center',width:'120'},
          { prop: "invoiceNo", label: "发票号码", center: 'center',width:'120'},
          { prop: "invoiceAmount", label: "开票金额", center: 'center',width:'120',
            render: (h, data) => {
              return (data.row.invoiceAmount>0?<span>{data.row.invoiceAmount}</span>:<span style="color:red">{data.row.invoiceAmount}</span>)
            },
          },
          // 发票状态 1-正常 2-已作废 3-已红冲
          { prop: "invoiceStatus", label: "发票状态", center: 'center',width:'120',
            formatter: (row) => {
              const typeMap = {
                '1': '正常',
                '2': '已作废',
                '3': '已红冲',
              }
              return typeMap[row.invoiceStatus];
            },
          },
          { prop: "pdfStatus", label: "PDF生成状态", center: 'center',width:'120',
            formatter: (row) => {
                const typeMap = {
                  '0': '未生成',
                  '1': '已生成',
                }
                return typeMap[row.pdfStatus];
              },
          },
          { prop: "pushStatus", label: "邮箱推送状态", center: 'center',width:'120',
            formatter: (row) => {
                  const typeMap = {
                    '0': '未推送',
                    '1': '已推送',
                  }
                  return typeMap[row.pushStatus];
                },
          },
          { prop: "createTime", label: "数据写入时间", center: 'center',width:'160',tooltip:true},
          { label: "操作",width:'182', center: 'center', fixed:"right",
            render: (h, data) => {
              return (
                <span style="display: flex; justify-content: space-around;">
                  <el-button class='button' type="text" onClick={() => { this.preview(data.row);}}>
                    预览
                  </el-button>
                </span>
              )
            },
          }
        ],
        tableData: [],
        isdeling: false,
       
        exportVis: false,
        exportType:'2',
        multipleSelection: [],
        requestFrom:"einv",
        isSearch:this.$route.query.isSearch || false,
        selectOptions:[],
        mulSelectLoading:false,
        id:'',

        // 
        itemId:'null',
        imgUrl:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        previewsVis:false,//预览
        pushVis:false, //推送
        autoPush:false, //重新生成pdf自动推送
        newPdfData:{}, //重新生成pdf的数据
        newPushData:{}, //批量推送邮箱的数据
      }
    },
    methods: {
      //----开票日期----
      beginDate(){
        const self = this
        return {
          disabledDate(time){
            if (self.endDate) {
              //如果结束时间不为空，则小于结束时间
              //时区转换
              var ntime = new Date(time.getTime())
              ntime.setHours(ntime.getHours() + 8)
              return new Date(self.endDate).getTime() < ntime.getTime()
            }
          }
        }
      },
      processDate() {
        const  self = this
        return {
          disabledDate(time) {
            if (self.startDate) {  //如果开始时间不为空，则结束时间大于开始时间
              //时区转换
              var ntime = new Date(time.getTime())
              ntime.setHours(ntime.getHours() + 8)
              return new Date(self.startDate).getTime() > ntime.getTime()
            }
          }
        }
      },
      //----数据写入----
      createStartDate(){
        const self = this
        return {
          disabledDate(time){
            if (self.createEnd) {
              //如果结束时间不为空，则小于结束时间
              //时区转换
              var ntime = new Date(time.getTime())
              ntime.setHours(ntime.getHours() + 8)
              return new Date(self.createEnd).getTime() < ntime.getTime()
            }
          }
        }
      },
      createEndDate() {
        const  self = this
        return {
          disabledDate(time) {
            if (self.createStart) {  //如果开始时间不为空，则结束时间大于开始时间
              //时区转换
              var ntime = new Date(time.getTime())
              ntime.setHours(ntime.getHours() + 8)
              return new Date(self.createStart).getTime() > ntime.getTime()
            }
          }
        }
      },
      handleQuery(){
        console.log(this.queryForm.invoiceNoStart,'queryForm.invoiceNoStart');
        this.queryForm.page = 1
        this.getList()
      },
      //重置
      handleReset() {
        this.startDate = moment().month(moment().month()).startOf('month').format('YYYY-MM-DD'),//当前月开始时间,
        this.endDate = moment().month(moment().month()).endOf('month').format('YYYY-MM-DD'),//当前月结束时间,
        this.createStart = undefined,
        this.createEnd = undefined,
        this.queryForm = {
          issueType: '', //开票类型
          invoiceTimeBegin: '',
          invoiceTimeEnd: '',
          buyerName: '',
          drawer:'', //开票人
          billingType :'', //开票类型
          invoiceStatus: [],
          invoiceNoStart: '',
          invoiceNoEnd: '',
          createTimeBegin: '', //数据写入日期开始
          createTimeEnd: '',  //数据写入日期截止
          productName: '',
          invoiceCode: '',
          page: 1,
          rows: 20
        }
        this.$refs.queryForm.resetFields()
        this.getList()
      },
      // 切换表格分页
      changeSize(size) {
        this.queryForm.rows = size
        this.getList();
      },
      handleCurrentChange(current){
        this.queryForm.page = current
        this.getList();
      },
      selection(val){
        // this.multipleSelection = val.map(e=>{return e.invoiceId});
        this.multipleSelection = val;
        console.log(this.multipleSelection,'this.multipleSelection')
        this.id = this.multipleSelection.map(item => {
                  return item.invoiceId
                }).join(',')
      },
      beforeQuery(){
        if(!this.getToken('companyId')){
          // einvAlert.warning("提示","请设置当前公司")
          this.$router.push('/home')
          // localStorage.setItem('sideLink', '/home')
          return false;
        }
        if(this.queryForm.invoiceNoStart !== '' && this.queryForm.invoiceNoEnd !== ''
            && Number(this.queryForm.invoiceNoStart) > Number(this.queryForm.invoiceNoEnd)){
          einvAlert.warning("提示","起始发票号码不能大于结束发票号码")
           return false;
        }
        if (this.startDate !== undefined && this.startDate !== null) {
          this.queryForm.invoiceTimeBegin = this.startDate + ' 00:00:00'
        }else{
          this.queryForm.invoiceTimeBegin = ''
        }
        if (this.endDate !== undefined && this.endDate !== null) {
          this.queryForm.invoiceTimeEnd =this.endDate + ' 23:59:59'
        }else{
          this.queryForm.invoiceTimeEnd = ''
        }
        // ------数据写入开始------
        if(this.queryForm.createTimeBegin !== '' && this.queryForm.createTimeEnd !== ''
            && Number(this.queryForm.createTimeBegin) > Number(this.queryForm.createTimeEnd)){
          einvAlert.warning("提示","起始发票号码不能大于结束发票号码")
           return false;
        }
        if (this.createStart !== undefined && this.createStart !== null) {
          this.queryForm.createTimeBegin = this.createStart + ' 00:00:00'
        }else{
          this.queryForm.createTimeBegin = ''
        }
        if (this.createEnd !== undefined && this.createEnd !== null) {
          this.queryForm.createTimeEnd =this.createEnd + ' 23:59:59'
        }else{
          this.queryForm.createTimeEnd = ''
        }
        return true;
      },
      // 已开发票列表
      getList() {
        if(!this.beforeQuery()){
          return;
        }
        this.isLoading = true;
        this.isExport = false;
        this.$store.dispatch("findInvoiceIssued",
            {
              userId: this.getToken('userId'),
              ...this.queryForm
            }
        ).then(res => {
          if (res.success) {
            this.isLoading = false;
            this.tableData = res.data
            this.total = res.totalCount
            if(res.data.length>0){
              this.isExport = true;
            }
            if(res.data.length == '0' && res.totalCount>0 && this.queryForm.page>1){
              this.queryForm.page = this.queryForm.page -1;
              this.getList();
            }
          } else {
            einvAlert.error("提示",res.msg)
          }
          this.exportFrom = this.queryForm
        }).catch(err => {
           this.isLoading = false;
           this.isExport = true;
          //einvAlert.error("提示",err)
        })
      },
      batchExport(){
        if(!this.isExport){
          einvAlert.error("提示",'当前无数据，请检查')
          return
        }
        // if(this.multipleSelection.length == 0){
        //    einvAlert.warning('提示','请先勾选',true,'确定')
        //   this.isdeling = false
        //   this.exportVis = false
        //   return
        // }
        this.exportVis = true
      },
      selectType(value){
        this.exportType = value
      },
      
      
      // //点击预览获取文件
      //   getFileUrl(item){
      //     this.$refs.pdf.getFileUrl(item)
      //   },
      
      
      //----------导出 查询后的数据-----------
      confirmExport(val) {
        
        this.exportType = val;
        // this.isdeling = true
        
        // var ids =''
        // this.multipleSelection.forEach(e=>{
        //   ids += e+','
        // })
        // ids = ids.slice(0,ids.lastIndexOf(','))
        // console.log(ids,'ids===')
        console.log(this.multipleSelection,'this.multipleSelection')
        if(this.exportType === '1' && this.multipleSelection.length == 0){
          einvAlert.warning('提示','请先勾选',true,'确定')
          this.isdeling = false
          this.exportVis = false
          return
        }
        axios({
          method: 'get',
          url: '/api/einvoice/invoiceManager/export',
          responseType: 'blob',
          params: {
            // agencyCompanyId: this.getToken('agencyCompanyId'),
            // companyId: this.getToken('companyId'),
            userId: this.getToken('userId'),
            ids: this.exportType === '1'?this.id:'',
            exportType: this.exportType,
            ...this.exportFrom
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let fileReader = new FileReader();
          fileReader.onload = function() {
            try {
              let jsonData = JSON.parse(this.result);  // 说明是普通对象数据，后台转换失败
              if (jsonData.status == '40301') { // 接口返回的错误信息
                einvAlert.error("错误",jsonData.message) // 弹出的提示信息
              }
            } catch (err) {   // 解析成对象失败，说明是正常的文件流
              let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = url;
              var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
              link.setAttribute('download', '已开发票' + now + '.xls')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            }
          };
          fileReader.readAsText(data)  // 注意别落掉此代码，可以将 Blob 或者 File 对象转根据特殊的编码格式转化为内容(字符串形式)
          this.isdeling = false
          this.exportVis = false
        }).catch(err=>{
          this.isdeling = false
          //einvAlert.error("提示",err)
        })
      },
      exportCancel(){
        this.exportVis = false;
      },
      
      //----------重新生成PDF------------
      createPDF(){
         if(this.multipleSelection.length == 0){
           einvAlert.warning('提示','请先勾选',true,'确定')
          this.isdeling = false
          this.exportVis = false
          return
        }
        
        //生成pdf接口
        // let data = {companyId: this.getToken('companyId'),userId: this.getToken('userId'), idList: ids, };
        //    insurancesList(data).then(res => {
        //     this.isLoading = false;
        //     if (res.success) {
              // this.autoPush = true
        //     } else {
        //       einvAlert.error("提示",res.msg)
        //     }
        //     }).catch(err => {
        //       this.isLoading = false;
        //     // einvAlert.error("提示",err)
        //     })
         this.autoPush = true

      },
      //子传父数据
      selectEmailType(val){
          // console.log(val,'val123')
      },
      
      //----------发票状态多选-------------
      mockData(){
        this.mulSelectLoading = true;
        const options = [
          { label: '正常', value: '1,3' },
          { label: '已红冲', value: '4' },
          { label: '已作废', value: '2' },
          { label: '失控', value: '8' },
          // 正常、已作废、已红冲、失控
        ]

        this.selectOptions = options;
        this.mulSelectLoading = false;
        // this.mulSelecteds = ['cq','nj'];
      },
      updateMultipleSelect(val){
        this.queryForm.invoiceStatus=val;
      },
      changeInvoiceStatusToStr(val){
        let invoiceStatusStr ='';
        if(val!=null&&val.length>0){
          for(var i=0;i<val.length;i++){
            if(i==0&&val[i]!='全选'){
              invoiceStatusStr=val[0];
            }else if(i!=0){
              if(invoiceStatusStr==''){
                invoiceStatusStr=val[i];
              }else{
                invoiceStatusStr=val[i]+","+invoiceStatusStr;
              }
            }
          }
        }
        return invoiceStatusStr;
      },

      //----------预览-----------
      preview(data){ 
         this.$refs.pdf.getFileUrl(data) 
      },
      previewsCancel(){
        this.previewsVis = false
      },
      //---------推送邮箱-----------
      pushEmail(){
        if(this.multipleSelection.length == 0){
           einvAlert.warning('提示','请先勾选',true,'确定')
          this.isdeling = false
          this.exportVis = false
          return
        }
        this.pushVis = true
      },
      pushCancel(){
        this.pushVis = false
      },
      pushConfirm(data){
         this.isdeling = true
        console.log('pushVis',data);
        let list = this.multipleSelection
        this.newPushData = {email:data.email,pushPdfDtos:list}
        axios({
          method: 'post',
          url: '/api/sks/invoice/pushPdfBatch',
          data: this.newPushData,
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          },
        }).then(res => {
          if (res.data.success) {
              einvAlert.success("提示",res.data.msg)
              this.isdeling = false;
              this.getList();
             
          } else {
            this.isdeling = false;
            this.$message.error(res.data.msg)
          }
          
        }).catch(err => {
          this.$message.error(err)
          this.isLoading = false
          this.isdeling = false
        })
        this.pushVis = false
      },
      //---------自动推送邮箱-----------
      autoPushCancel(){
        this.autoPush = false
      },
      autoPushConfirm(val){
        this.isdeling = true
        console.log('autoPush',val);
        //如果要推送邮箱，调取推送邮箱接口
        // if(val.type == '1'){
        //   console.log('推送邮箱')
        // }
        console.log(this.multipleSelection,'this.multipleSelection')
        let list = this.multipleSelection
        this.newPdfData = { needSend:val.type == '1'?true:false,email:val.email,genPdfDtos:list}
        console.log(this.newPdfData,'this.newPdfData')
        axios({
          method: 'post',
          url: '/api/sks/invoice/genPdfBatch',
          data: this.newPdfData,
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          },
        }).then(res => {
          if (res.data.success) {
              einvAlert.success("提示","提交成功")
              this.isdeling = false;
              this.getList();
          } else {
            this.$message.error(res.data.msg)
          }
          this.isLoading = false
          this.isdeling = false
          this.delDialog = false
        }).catch(err => {
          this.$message.error(err)
          this.isLoading = false
          this.isdeling = false
        })

        this.autoPush = false
      },
      
    },
    mounted() {
      this.mockData();
      this.getList();
    },
    watch:{
      queryForm:{
        deep: true,
        immediate:true,
        handler(){
          // 去掉输入框中首位空格
          let keys = Object.keys(this.queryForm);
          keys.forEach(item =>{
            if(typeof(this.queryForm[item])=='string'){
              this.queryForm[item] = this.queryForm[item].trim()
            }
          })
        }
      }
    }
  }
</script>
<style lang="less">
   .invoice-account{
     .el-tag{
       min-width: 3rem !important;
     }
   }
  .single_query{
    .el-form-item__content {
      width: 60% !important;
    }
  }
  .range-query{
    .el-form-item__content {
      width: 85% !important;
    }
    .filter-item{
      width: 45%;
    }
  }
  .tz {
    .el-form-item{
      margin-right: 1rem !important;
    }
  }
  .button_query{
    float: right;
    .el-form-item__content {
      width: 100% !important;
    }
  }
</style>

<style lang="less" scoped>
  .button{
    margin-right: 10px;
  }
  .batch_button{
    padding-top: 10px;
    margin-top: 15px;
    background: #ffffff;
    .btn1{
      margin-right: 10px;
      background: #409eff;
      border: 1px solid #409eff;
      border-radius: 8px;
      color: #ffffff;
      height: 40px;
      width: 120px;
    }
    // .btn2{
    //   margin-right: 10px;
    //   background: #e8f5ff;
    //   border: 1px solid #409eff;
    //   border-radius: 8px;
    //   color: #409eff;
    //   height: 40px;
    //   width: 160px;
    // }
    .btnBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 24px;
      .el-icon-question {
        color: #a7a7a7;
        font-size: 20px;
      }
      .el-icon-question:hover {
        color: #409eff;
        font-size: 20px;
      }

    }
  }
  .zhineng {
    position: relative;
  }
  
  .tip {
    position: absolute;
    top: 60px;
    right: 0;
    width: 300px;
    height: 175px;
    padding:17px 12px;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    box-shadow: -3px 1px 10px 0px rgba(169,169,169,0.26);
    font-size: 14px;
    line-height: 24px;
    z-index: 99;
    p {
      margin: 0;

    }

  }
   .table {
     padding: 24px 20px;
     padding-bottom: 3rem;
     background-color: #fff;
     .comp_table {
       border: 1px solid #ebeef5;
     };

   }
   .answer {
     text-align: center;
     padding: 24px 0 14px;
     font-size: 16px;
   }
   /deep/.removeControl {
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input[type="number"]{
        -moz-appearance: textfield;
      }
   }
   /deep/.el-table__body-wrapper{
    height: 410px;
    overflow-y: auto;
  }
    /deep/ .el-table__fixed-body-wrapper {
          overflow-y: auto; // fixed的列也要超出滚动
          height: 400px;  // 出现滚动条需要比表格短10px
        }
</style>
